/* eslint-disable @typescript-eslint/no-misused-promises */
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@sherweb/core/components/Form'
import Switch from '@sherweb/core/components/Switch'

import { useGetConnectWiseConfiguredQuery } from '@rsp/modules/integrations/helpdesk'
import { useReseller, useSetResellerSettingsMutation } from '@rsp/modules/reseller'

import { SubmitButton } from '../components/SubmitButton'
import resellerSettingsSchema from '../validationSchema/resellerSettingsSchema'
import { ResellerSettingsUserManagement } from './ResellerSettingsUserManagement'

const ResellerSettings: React.FC = () => {
  const { t } = useTranslation()
  const { data: reseller } = useReseller()
  const setResellerSettingsMutation = useSetResellerSettingsMutation()

  const hasConnectWiseIntegration = useGetConnectWiseConfiguredQuery()?.isEnabled

  const form: UseFormReturn<z.infer<typeof resellerSettingsSchema>> = useForm<
    z.infer<typeof resellerSettingsSchema>
  >({
    resolver: zodResolver(resellerSettingsSchema),
  })

  useEffect(() => {
    if (reseller?.id) {
      form.setValue('priceVisible', reseller?.resellerSettings?.priceVisible ?? false)
      form.setValue('subresellerVisible', reseller?.resellerSettings?.isSubresellerShown ?? false)
      form.setValue('showUsers', reseller?.resellerSettings?.showUsers ?? false)
      form.setValue('showBilling', reseller?.resellerSettings?.showBilling ?? false)
      form.setValue('showLicenses', reseller?.resellerSettings?.showLicenses ?? false)
      form.setValue('showHelpDesk', reseller?.resellerSettings?.showHelpDesk ?? false)
      form.setValue('showSubscriptions', reseller?.resellerSettings?.showSubscriptions ?? false)
      form.setValue(
        'orgAdminCanCreateUsers',
        reseller?.resellerSettings?.orgAdminCanCreateUsers ?? false
      )
      form.setValue(
        'allowSubscriptionCancellation',
        reseller?.resellerSettings?.allowSubscriptionCancellation ?? false
      )
    }
  }, [form, reseller])

  function onSubmit(values: z.infer<typeof resellerSettingsSchema>) {
    setResellerSettingsMutation.mutate({
      priceVisible: values.priceVisible,
      isSubresellerShown: values.subresellerVisible,
      showSubscriptions: values.showSubscriptions,
      showUsers: values.showUsers,
      showLicenses: values.showLicenses,
      showBilling: values.showBilling,
      showHelpDesk: values.showHelpDesk,
      orgAdminCanCreateUsers: values.orgAdminCanCreateUsers,
      allowSubscriptionCancellation: values.allowSubscriptionCancellation,
    })
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="my-4 flex flex-col gap-y-16 rounded-xl p-4 dark:bg-slate-900 md:px-8 md:py-16"
      >
        <div className="grid-col-1 grid gap-y-4 md:grid-cols-2 md:gap-y-0">
          <div className="w-full sm:w-9/12">
            <label className="font-semibold">
              {t('rsp:pages.settings.resellerSettings.subscriptions.title')}
            </label>
            <div className="text-xs text-gray-500 dark:text-slate-300">
              {t('rsp:pages.settings.resellerSettings.subscriptions.description')}
            </div>
          </div>

          <div className="w-full">
            <div className="flex flex-col gap-8">
              <div className="grid grid-cols-1 gap-y-4">
                <FormField
                  control={form.control}
                  name="priceVisible"
                  render={({ field }) => (
                    <FormItem className="settings-form-item">
                      <FormLabel>
                        {t('rsp:pages.settings.resellerSettings.subscriptions.labels.priceToggle')}
                      </FormLabel>
                      <FormControl className="settings-form-control">
                        <Switch
                          checked={field.value}
                          onCheckedChange={field.onChange}
                          data-testid="priceVisible"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="allowSubscriptionCancellation"
                  render={({ field }) => (
                    <FormItem className="settings-form-item">
                      <FormLabel>
                        {t(
                          'rsp:pages.settings.resellerSettings.subscriptions.labels.allowSubscriptionCancellation'
                        )}
                      </FormLabel>
                      <FormControl className="settings-form-control">
                        <Switch
                          checked={field.value}
                          onCheckedChange={field.onChange}
                          data-testid="allowSubscriptionCancellation"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="grid-col-1 grid gap-y-4 md:grid-cols-2 md:gap-y-0">
          <div className="w-full sm:w-9/12">
            <label className="font-semibold">
              {t('rsp:pages.settings.resellerSettings.subresellers.title')}
            </label>
            <div className="text-xs text-gray-500 dark:text-slate-300">
              {t('rsp:pages.settings.resellerSettings.subresellers.description')}
            </div>
          </div>

          <div className="w-full">
            <div className="flex flex-col gap-8">
              <div className="grid grid-cols-1 gap-y-4">
                <FormField
                  control={form.control}
                  name="subresellerVisible"
                  render={({ field }) => (
                    <FormItem className="settings-form-item">
                      <FormLabel>
                        {t(
                          'rsp:pages.settings.resellerSettings.subresellers.labels.displaySubresellers'
                        )}
                      </FormLabel>
                      <FormControl className="settings-form-control">
                        <Switch
                          checked={field.value}
                          onCheckedChange={field.onChange}
                          data-testid="subresellerVisible"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="grid-col-1 grid gap-y-4 md:grid-cols-2 md:gap-y-0">
          <div className="w-full sm:w-9/12">
            <label className="font-semibold">
              {t('rsp:pages.settings.resellerSettings.applicationFeatures.title')}
            </label>
            <div className="text-xs text-gray-500 dark:text-slate-300">
              {t('rsp:pages.settings.resellerSettings.applicationFeatures.description')}
            </div>
          </div>
          <div className="w-full">
            <div className="flex flex-col gap-8">
              <div className="grid grid-cols-1 gap-y-4">
                <FormField
                  control={form.control}
                  name="showSubscriptions"
                  render={({ field }) => (
                    <FormItem className="settings-form-item">
                      <FormLabel>
                        {t(
                          'rsp:pages.settings.resellerSettings.applicationFeatures.labels.showSubscriptionsManagement'
                        )}
                      </FormLabel>
                      <FormControl className="settings-form-control">
                        <Switch
                          checked={field.value}
                          onCheckedChange={field.onChange}
                          data-testid="showSubscriptions"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="showUsers"
                  render={({ field }) => (
                    <FormItem className="settings-form-item">
                      <FormLabel>
                        {t(
                          'rsp:pages.settings.resellerSettings.applicationFeatures.labels.showUsersManagement'
                        )}
                      </FormLabel>
                      <FormControl className="settings-form-control">
                        <Switch
                          checked={field.value}
                          onCheckedChange={field.onChange}
                          data-testid="showUsers"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="showLicenses"
                  render={({ field }) => (
                    <FormItem className="settings-form-item">
                      <FormLabel>
                        {t(
                          'rsp:pages.settings.resellerSettings.applicationFeatures.labels.showLicensesManagement'
                        )}
                      </FormLabel>
                      <FormControl className="settings-form-control">
                        <Switch
                          checked={field.value}
                          onCheckedChange={field.onChange}
                          data-testid="showLicenses"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {hasConnectWiseIntegration ? (
                  <FormField
                    control={form.control}
                    name="showHelpDesk"
                    render={({ field }) => (
                      <FormItem className="settings-form-item">
                        <FormLabel>
                          {t(
                            'rsp:pages.settings.resellerSettings.applicationFeatures.labels.showHelpDesk'
                          )}
                        </FormLabel>
                        <FormControl className="settings-form-control">
                          <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                            data-testid="showHelpDesk"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <ResellerSettingsUserManagement />
        <SubmitButton
          disabled={setResellerSettingsMutation?.isLoading}
          showLoader={setResellerSettingsMutation?.isLoading}
        />
      </form>
    </Form>
  )
}

export default ResellerSettings
