import { useQuery } from '@tanstack/react-query'

import { useAuthenticationLoggedInState } from '@sherweb/core/modules/authentication'

import { useSelectedResellerId } from '@rsp/modules/reseller'

import { resellerAdminsAndGuests } from './users.queries'

export const useGetResellerAdminsAndGuestsQuery = () => {
  const resellerId = useSelectedResellerId()

  const { isLoggedIn } = useAuthenticationLoggedInState()

  return useQuery({
    queryKey: resellerAdminsAndGuests.queryKey(resellerId),
    queryFn: async () => await resellerAdminsAndGuests.queryFn(resellerId),
    enabled: !!resellerId && !!isLoggedIn,
    staleTime: resellerAdminsAndGuests.staleTime,
  })
}
