import { Slot } from '@radix-ui/react-slot'
import { Dispatch, SetStateAction } from 'react'

import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

import List from '../../List'
import { Typography } from '../../Typography'
import { CollapsibleColumn } from './types'

type CollapsibleDataRowProps<TData, TNestedData> = {
  childrens: TNestedData[]
  columns: CollapsibleColumn<TData, TNestedData>
  parentData?: TData
  setData: Dispatch<SetStateAction<TData[]>>
  className?: string
}
export const CollapsibleDataRow = <TData, TNestedData extends { id?: string }>({
  parentData,
  childrens,
  columns,
  setData,
  className,
}: CollapsibleDataRowProps<TData, TNestedData>) => (
  <>
    {childrens?.map(children => (
      <Slot key={children.id}>
        <>
          {columns.map(column => {
            if (column.id === 'select' && typeof column.header === 'function') {
              return (
                <List.Item
                  key={`collapse-select-${String(column.accessorKey ?? column.id)}`}
                  className={mergeClassName(
                    'first:border-1 col-span-2 flex justify-end first:rounded-none first:border-solid hover:cursor-default',
                    className
                  )}
                >
                  {column?.cell?.({
                    data: children,
                    setData,
                    isCollapsible: false,
                  })}
                </List.Item>
              )
            }

            return (
              <List.Item
                key={`collapse-${String(column.accessorKey ?? column.id)}`}
                className={mergeClassName(
                  'first:border-1 first:rounded-none first:border-solid col-span-2 px-4 py-4 md:px-8 hover:cursor-default',
                  column.className
                )}
              >
                <Typography variant="body2" as="div">
                  {column?.render
                    ? column?.render({
                        data: children,
                        accessorKey: column?.accessorKey,
                        parentData,
                      })
                    : null}
                  {!column?.render && (column?.accessorKey as unknown as keyof TData)
                    ? (children as any)?.[column?.accessorKey]
                    : ''}
                </Typography>
              </List.Item>
            )
          })}
        </>
      </Slot>
    ))}
  </>
)
