import { removeUndefinedAndNullValues } from '@sherweb/core/utils/array'

import { useSetCatalogProductsMutation } from '@rsp/modules/martkeplace'
import { ICatalogOffer } from '@rsp/modules/martkeplace/core/marketplace.model'

import { useGetCatalogId } from '../../hooks/useGetCatalogId'

export const useUpdateCatalogProducts = () => {
  const { catalogId } = useGetCatalogId()

  const setCatalogProductsMutation = useSetCatalogProductsMutation(catalogId)

  const onUpdateCatalogProducts = (allOffers: ICatalogOffer[], selectedOffers: ICatalogOffer[]) => {
    const unSelectedOffers = allOffers.filter(
      offer => !selectedOffers.some(selectedOffer => selectedOffer.sku === offer.sku)
    )

    setCatalogProductsMutation.mutate({
      skusToAdd: removeUndefinedAndNullValues(selectedOffers?.map(offer => offer.sku)),
      skusToRemove: removeUndefinedAndNullValues(unSelectedOffers?.map(offer => offer.sku)),
    })
  }

  return {
    setCatalogProductsMutation,
    onUpdateCatalogProducts,
  }
}
