// based on: https://ui.shadcn.com/docs/components/data-table#installation

import {
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
} from '@tanstack/react-table'

import {
  DEFAULT_TABLE_ROWS_PER_PAGE,
  DEFAULT_TABLE_ROWS_PER_PAGE_OPTIONS,
} from '@sherweb/core/utils/const'

import { DataTestId } from '../../types/dataTestIdType'
import { Table } from '../Table'
import { DataTableBody } from './DataTableBody'
import { DataTableContainer } from './DataTableContainer'
import { DataTableFilterHeader } from './DataTableFilterHeader'
import { DataTableHeader } from './DataTableHeader'
import { useDataTable } from './hooks/useDataTable'
import Pagination from './Pagination'
import { DataTableProps } from './types'

const DataTable = <TColumns, TData>({
  columns,
  data = [],
  filterableFields = [],
  rowsPerPage = DEFAULT_TABLE_ROWS_PER_PAGE,
  rowsPerPageOptions = DEFAULT_TABLE_ROWS_PER_PAGE_OPTIONS,
  emptyMessage,
  filterPlaceholder,
  fieldDescription,
  className,
  optionalActions,
  dataTestId,
}: DataTableProps<TColumns, TData> & DataTestId) => {
  const minimalResultsPerPage = Math.min(...rowsPerPageOptions)

  const { table, setGlobalFilter } = useDataTable({
    data,
    columns,
    rowsPerPage,
    filterableFields,
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return (
    <>
      <DataTableFilterHeader
        filterableFields={filterableFields}
        onChange={setGlobalFilter}
        filterPlaceholder={filterPlaceholder}
        fieldDescription={fieldDescription}
      >
        {optionalActions}
      </DataTableFilterHeader>
      <DataTableContainer className={className}>
        <Table data-testid={dataTestId}>
          <DataTableHeader<TData> table={table} />
          <DataTableBody>
            {table.getRowModel().rows?.length ? (
              <DataTableBody.Content<TData> table={table} dataTestId={dataTestId} />
            ) : (
              <DataTableBody.EmptyContent
                colSpanLength={columns.length}
                emptyMessage={emptyMessage}
                dataTestId={dataTestId}
              />
            )}
          </DataTableBody>
        </Table>

        {data.length > minimalResultsPerPage && (
          <Pagination table={table} rowsPerPageOptions={rowsPerPageOptions} />
        )}
      </DataTableContainer>
    </>
  )
}

export default DataTable
