import { QueryKey, useQueryClient } from '@tanstack/react-query'

import {
  SearchAvailableCatalogProductsResponse,
  SetCatalogProductsCommand,
} from '@sherweb/core/openapi-generated/index.defs'

export const useOptimisticUpdateCatalogProducts = () => {
  const queryClient = useQueryClient()

  const updateProducts = (queryKey: QueryKey, variables: SetCatalogProductsCommand) => {
    const previousProducts =
      queryClient.getQueryData<SearchAvailableCatalogProductsResponse>(queryKey) ?? undefined

    queryClient.setQueryData<SearchAvailableCatalogProductsResponse>(queryKey, {
      ...previousProducts,
      availableCatalogProducts: previousProducts?.availableCatalogProducts?.map(product => ({
        ...product,
        offers: product.offers?.map(offer => {
          if (variables.skusToAdd?.includes(offer.sku ?? '')) {
            return {
              ...offer,
              isSelected: true,
            }
          }

          if (variables.skusToRemove?.includes(offer.sku ?? '')) {
            return {
              ...offer,
              isSelected: false,
            }
          }

          return offer
        }),
      })),
    })

    return previousProducts
  }

  return { updateProducts }
}
