import { QueryKey, UseInfiniteQueryResult, UseQueryResult } from '@tanstack/react-query'

import { PaginatedResults } from '@sherweb/core/components/DataTable/types'
import { removeUndefinedAndNullValues } from '@sherweb/core/utils/array'

export const localizedKey = 'Localized'

const filterEmptyValues = (keys: unknown[]) => {
  return keys.filter((key): key is string => !!key)
}

export const getLocalizedQueryKey = (keys: unknown[]): QueryKey => {
  return removeUndefinedAndNullValues(filterEmptyValues([localizedKey, ...keys]))
}

export const isRequestLoading = <T extends UseQueryResult>(query: T) => {
  return query?.isLoading || query?.data === undefined
}

export const isRequestHavingNoResults = <T extends UseQueryResult<unknown[]>>(query: T) => {
  return !query?.isLoading && !!query?.data && query?.data?.length === 0
}

export const isInfiniteScrollRequestHavingNoResults = <T>(
  query: UseInfiniteQueryResult<PaginatedResults<T>, unknown>
) => {
  return !query.isLoading && !!query.data && query.data?.pages?.[0]?.results?.length === 0
}

export const getAllPagesResults = <T>(
  query: UseInfiniteQueryResult<PaginatedResults<T>, unknown>
) => query?.data?.pages?.flatMap(({ results }) => results) as T[] | undefined

export const getQueryKey = (queryKey: Array<string | undefined>) =>
  removeUndefinedAndNullValues(queryKey)
