import {
  Facet,
  SearchAvailableCatalogProductsResponse,
} from '@sherweb/core/openapi-generated/index.defs'

import { IAvailableCatalogProductQueryResult, ICatalogOffer } from './marketplace.model'

export type BuildAvailableProductsReturn = {
  selectedOffers: ICatalogOffer[]
  totalOffersCount: number
  allOfferSkus?: string[]
  allOffers: ICatalogOffer[]
  facets?: Facet[]
  products: IAvailableCatalogProductQueryResult[]
}

export const buildAvailableProducts = (
  response?: SearchAvailableCatalogProductsResponse
): BuildAvailableProductsReturn => {
  const products = response?.availableCatalogProducts

  if (!products) {
    return {
      totalOffersCount: 0,
      selectedOffers: [],
      products: [],
      allOfferSkus: [],
      allOffers: [],
    }
  }

  let selectedOffers: ICatalogOffer[] = []
  let allOfferSkus: string[] = []
  let allOffers: ICatalogOffer[] = []

  const updatedProducts = products?.map(product => {
    return {
      ...product,
      offers:
        product?.offers?.map(offer => {
          allOfferSkus = [...allOfferSkus, offer?.sku ?? '']

          const updatedOffer = {
            ...offer,
            id: offer.sku,
          }

          allOffers = [...allOffers, updatedOffer]

          if (offer.isSelected) {
            selectedOffers = [...selectedOffers, { ...offer, id: offer.sku }]
          }

          return updatedOffer
        }) ?? [],
    }
  })

  return {
    facets: response?.facets,
    selectedOffers,
    totalOffersCount: allOffers.length,
    products: updatedProducts ?? [],
    allOfferSkus,
    allOffers,
  }
}
