import { CheckedState } from '@radix-ui/react-checkbox'
import { Dispatch, SetStateAction, useCallback } from 'react'

import { uniqueArrayByKey } from '@sherweb/core/utils/array'

import {
  IAvailableCatalogProductQueryResult,
  ICatalogOffer,
} from '@rsp/modules/martkeplace/core/marketplace.model'

export type UseSelectProps = {
  data?: ICatalogOffer
  setData: Dispatch<SetStateAction<IAvailableCatalogProductQueryResult[]>>
  checked: CheckedState
}

export const useSelectRow = ({
  setSelectedRows,
}: {
  setSelectedRows: Dispatch<SetStateAction<ICatalogOffer[]>>
}) => {
  const selectAll = useCallback(
    ({
      currentSelectedAll,
      data,
      handleSelectAll,
    }: {
      handleSelectAll: () => void
      data: IAvailableCatalogProductQueryResult[]
      currentSelectedAll: boolean
    }): void => {
      if (currentSelectedAll) {
        setSelectedRows([])
      } else {
        let rows: ICatalogOffer[] = []

        data.forEach(product => {
          product.offers?.forEach(offer => {
            rows = [...rows, { ...offer, isSelected: true }]
          })
        })

        setSelectedRows(rows)
      }

      handleSelectAll()
    },
    [setSelectedRows]
  )

  const selectCollapsibleRow = useCallback(
    ({ setData, data, checked }: UseSelectProps) => {
      setData(previousProducts => {
        return previousProducts?.map(product => {
          if (product.name === data?.name) {
            return {
              ...product,
              offers: product.offers?.map(offer => {
                const updatedOffer = {
                  ...offer,
                  isSelected: checked,
                }

                setSelectedRows(previousRows => {
                  if (checked) {
                    return uniqueArrayByKey([...previousRows, updatedOffer], 'sku')
                  }

                  if ([...previousRows].find(row => row.sku === updatedOffer.sku)) {
                    return [...previousRows].filter(row => row.sku !== updatedOffer.sku)
                  }

                  return previousRows
                })

                return updatedOffer
              }),
            }
          }

          return product
        })
      })
    },
    [setSelectedRows]
  )

  const selectSingleRow = useCallback(
    ({ data, checked, setData }: UseSelectProps) => {
      setSelectedRows(previousRows => {
        if (data) {
          if (checked && ![...previousRows].map(row => row.sku).includes(data.sku)) {
            return [...previousRows, { ...data, isSelected: checked }]
          }

          if ([...previousRows].map(row => row.sku).includes(data.sku)) {
            return previousRows.filter(row => row.sku !== data?.sku)
          }
        }

        return previousRows
      })

      setData(previousProducts => {
        return previousProducts?.map(product => ({
          ...product,
          offers: product.offers?.map(offer => {
            if (offer.sku === data?.sku) {
              return {
                ...offer,
                isSelected: checked,
              }
            }

            return offer
          }),
        }))
      })
    },
    [setSelectedRows]
  )

  const selectRow = useCallback(
    (props: { isCollapsible?: boolean } & UseSelectProps) => {
      const { isCollapsible, ...restProps } = props

      if (isCollapsible) {
        selectCollapsibleRow(restProps)
      } else {
        selectSingleRow(restProps)
      }
    },
    [selectCollapsibleRow, selectSingleRow]
  )

  return { selectAll, selectRow }
}
