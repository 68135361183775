import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { Money } from '@sherweb/core/openapi-generated/index.defs'

import { Checkbox } from '@sherweb/core/components/Checkbox'
import {
  CollapsibleDataContent,
  CollapsibleDataTable,
} from '@sherweb/core/components/DataTable/CollapsibleDataTable'
import {
  CollapsibleColumn,
  RenderChecboxCellProps,
  RenderCheckboxHeaderProps,
} from '@sherweb/core/components/DataTable/CollapsibleDataTable/types'
import { ScrollArea } from '@sherweb/core/components/ScrollArea'
import { orderBy } from '@sherweb/core/utils/array'
import { useMoneyFormatter } from '@sherweb/core/utils/money'

import {
  IAvailableCatalogProductQueryResult,
  ICatalogOffer,
} from '@rsp/modules/martkeplace/core/marketplace.model'

import { UseSelectProps, useSelectRow } from './hooks/useSelectRow'

const initialSort: { id: keyof IAvailableCatalogProductQueryResult; ascending: boolean } = {
  id: 'vendorName',
  ascending: true,
}

const renderCheckboxHeader = (
  props: RenderCheckboxHeaderProps<IAvailableCatalogProductQueryResult>,
  selectAll: (props: {
    handleSelectAll: () => void
    data: IAvailableCatalogProductQueryResult[]
    currentSelectedAll: boolean
  }) => void
) => {
  const { checked, ...restProps } = props

  return (
    <Checkbox
      variant="secondary"
      data-testid="checkbox-header"
      checked={checked}
      onClick={() => {
        selectAll(restProps)
      }}
    />
  )
}

const renderCheckboxCell = (
  props: RenderChecboxCellProps<IAvailableCatalogProductQueryResult, ICatalogOffer>,
  selectRow: (props: { isCollapsible?: boolean } & UseSelectProps) => void
) => (
  <Checkbox
    data-testid={`checkbox-${props?.data?.id as string}`}
    variant="secondary"
    checked={props?.data?.isSelected}
    onClick={event => {
      event.stopPropagation()
    }}
    onCheckedChange={checked => {
      selectRow({
        ...props,
        checked,
      })
    }}
  />
)

type MarketplaceCatalogsAddProductsTableProps = {
  filteredProducts?: IAvailableCatalogProductQueryResult[]
  totalOffersCount: number
  offers: ICatalogOffer[]
  onSelectOffers: Dispatch<SetStateAction<ICatalogOffer[]>>
}

export const MarketplaceCatalogsAddProductsTable = ({
  filteredProducts,
  totalOffersCount,
  offers,
  onSelectOffers,
}: MarketplaceCatalogsAddProductsTableProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const formatMoneyAmount = useMoneyFormatter<Money>(language)

  const { selectAll, selectRow } = useSelectRow({
    setSelectedRows: onSelectOffers,
  })

  const columns: CollapsibleColumn<IAvailableCatalogProductQueryResult, ICatalogOffer> = [
    {
      id: 'select',
      header: props => renderCheckboxHeader(props, selectAll),
      cell: props => renderCheckboxCell(props, selectRow),
    },
    {
      id: 'vendorName',
      header: t('rsp:pages.marketplace.addProducts.table.vendor'),
      onSort: ({ ascending, setData, data }) => {
        setData?.(orderBy(data, 'vendorName', !ascending))
      },
      render({ parentData, data }) {
        return parentData?.vendorName ?? data?.vendor
      },
    },
    {
      accessorKey: 'sku',
      header: t('rsp:pages.marketplace.addProducts.table.sku'),
    },
    {
      accessorKey: 'name',
      className: 'col-span-4',
      header: t('rsp:pages.marketplace.addProducts.table.productName'),
      onSort: ({ ascending, setData, data }) => {
        setData?.(orderBy(data, 'name', !ascending))
      },
    },
    {
      accessorKey: 'price',
      header: t('rsp:pages.marketplace.addProducts.table.price'),
      render: ({ data }) => formatMoneyAmount(data?.price),
    },
  ]

  if (!filteredProducts) {
    return null
  }

  return (
    <CollapsibleDataTable
      columns={columns}
      data={filteredProducts}
      isHeaderFixed
      nestedChildrenTotalCount={totalOffersCount}
      nestedChildrenType="offers"
      initialSort={initialSort}
      emptyMessage={t('rsp:pages.marketplace.addProducts.filters.emptyResult')}
      emptyClassName="mx-4 md:mx-12 w-[inherit] min-h-[200px] md:min-h-72"
      selectedRows={offers}
      renderCollapsibleRow={contentProps => (
        <ScrollArea className="mt-9 flex grow overflow-y-auto py-4">
          <CollapsibleDataContent {...contentProps} />
        </ScrollArea>
      )}
    />
  )
}
