import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
  CatalogListQueryResult,
  CatalogOrganizationSearchCommand,
  SetCatalogProductsCommand,
  UpdateCatalogOrganizationsCommand,
} from '@sherweb/core/openapi-generated/index.defs'
import { ResellerCatalogsService } from '@sherweb/core/openapi-generated/ResellerCatalogsService'

import { errorNotification, successNotification } from '@sherweb/core/components/ToastNotifications'
import { useAuthenticationLoggedInState } from '@sherweb/core/modules/authentication'
import { missingParametersError } from '@sherweb/core/utils/error'

import { useSelectedResellerId } from '@rsp/modules/reseller'

import { buildAvailableProducts } from './marketplace.builder'
import { getCatalogOrganizationsQueryOptions } from './marketplace.const'
import { useOptimisticUpdateCatalogProducts } from './marketplace.helpers'
import {
  availableCatalogs,
  availableProducts,
  catalogProducts,
  getAssignedCatalogOrganizationIds,
  queryCatalogOrganizations,
} from './marketplace.queries'

export const useGetAvailableCatalogsQuery = <TResult = CatalogListQueryResult[]>(
  options?: Omit<UseQueryOptions<CatalogListQueryResult[], Error, TResult>, 'queryKey'>
) => {
  const resellerId = useSelectedResellerId()

  return useQuery<CatalogListQueryResult[], Error, TResult>({
    queryKey: availableCatalogs.queryKey(resellerId),
    queryFn: async () => await availableCatalogs.queryFn(resellerId),
    enabled: !!resellerId,
    staleTime: availableCatalogs.staleTime,
    ...options,
  })
}

export const useGetCatalogQuery = (catalogId?: string) => {
  return useGetAvailableCatalogsQuery({
    select: catalogs => catalogs?.find(catalog => catalog.id === catalogId),
  })
}

export const useGetCatalogProductsQuery = (catalogId?: string) => {
  const resellerId = useSelectedResellerId()

  return useQuery({
    queryKey: catalogProducts.queryKey(resellerId, catalogId),
    queryFn: async () => await catalogProducts.queryFn(resellerId, catalogId),
    enabled: !!resellerId && !!catalogId,
    staleTime: catalogProducts.staleTime,
  })
}

export const useGetAvailableProductsQuery = (catalogId?: string) => {
  const resellerId = useSelectedResellerId()

  return useQuery({
    queryKey: availableProducts.queryKey(resellerId, catalogId),
    queryFn: async () => await availableProducts.queryFn(resellerId, catalogId),
    select: products => buildAvailableProducts(products),
    enabled: !!resellerId && !!catalogId,
    staleTime: availableProducts.staleTime,
  })
}

export const useGetInfiniteScrollQueryCatalogOrganizations = (
  catalogId?: string,
  queryOptions?: Partial<CatalogOrganizationSearchCommand>
) => {
  const resellerId = useSelectedResellerId()

  const updatedQueryOptions = {
    ...getCatalogOrganizationsQueryOptions(),
    ...queryOptions,
  }

  const { isLoggedIn } = useAuthenticationLoggedInState()

  return useInfiniteQuery({
    queryKey: queryCatalogOrganizations.queryKey(resellerId, catalogId, updatedQueryOptions),
    queryFn: async ({ pageParam = 1 }) =>
      await queryCatalogOrganizations.queryFn(resellerId, catalogId, {
        ...updatedQueryOptions,
        page: pageParam,
      }),
    enabled: isLoggedIn && !!resellerId && !!catalogId,
    keepPreviousData: true,
    staleTime: queryCatalogOrganizations.staleTime,
    getNextPageParam: lastPage => (lastPage?.hasNextPage ? lastPage?.pageIndex + 1 : null),
  })
}

export const useSetCatalogProductsMutation = (catalogId?: string) => {
  const { t } = useTranslation()

  const resellerId = useSelectedResellerId()

  const { updateProducts } = useOptimisticUpdateCatalogProducts()

  return useMutation({
    mutationFn: async (command: SetCatalogProductsCommand) => {
      if (!resellerId || !catalogId) {
        throw missingParametersError()
      }
      return await ResellerCatalogsService.setProducts({
        resellerId,
        catalogId,
        command,
      })
    },
    onSuccess: async (__, variables) => {
      const queryKey = availableProducts.queryKey(resellerId, catalogId)

      updateProducts(queryKey, variables)

      successNotification(
        t('rsp:pages.marketplace.addProducts.confirmation.updateCatalogSuccessMessage')
      )
    },
    onError: () => {
      errorNotification(
        t('rsp:pages.marketplace.addProducts.confirmation.updateCatalogFailureMessage')
      )
    },
  })
}

export const useGetAssignedCatalogOrganizationIdsQuery = (catalogId?: string) => {
  const resellerId = useSelectedResellerId()

  return useQuery({
    queryKey: getAssignedCatalogOrganizationIds.queryKey(resellerId, catalogId),
    queryFn: async () => await getAssignedCatalogOrganizationIds.queryFn(resellerId, catalogId),
    enabled: !!resellerId && !!catalogId,
    staleTime: getAssignedCatalogOrganizationIds.staleTime,
  })
}

export const useUpdateCatalogOrganizationsMutation = (catalogId?: string) => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const resellerId = useSelectedResellerId()

  return useMutation({
    mutationFn: async (command: UpdateCatalogOrganizationsCommand) => {
      if (!resellerId || !catalogId) {
        throw missingParametersError()
      }
      return await ResellerCatalogsService.updateOrganizations({
        resellerId,
        catalogId,
        command,
      })
    },
    onSuccess: async () => {
      successNotification(
        t('rsp:pages.marketplace.addProducts.confirmation.updateCatalogSuccessMessage')
      )

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: getAssignedCatalogOrganizationIds.queryKey(resellerId, catalogId),
        }),
        queryClient.invalidateQueries({
          queryKey: queryCatalogOrganizations.queryKey(resellerId, catalogId),
        }),
      ])
    },
    onError: () => {
      errorNotification(
        t('rsp:pages.marketplace.addProducts.confirmation.updateCatalogFailureMessage')
      )
    },
  })
}
