import React, { useCallback, useEffect, useState } from 'react'

import {
  DEFAULT_SERVER_DEBOUNCE_MS,
  DEFAULT_TABLE_ROWS_PER_PAGE,
  DEFAULT_TABLE_ROWS_PER_PAGE_OPTIONS,
} from '@sherweb/core/utils/const'

import { DataTestId } from '../../types/dataTestIdType'
import { Table } from '../Table'
import { DataTableBody, DataTableBodyContentEmpty } from './DataTableBody'
import { DataTableContainer } from './DataTableContainer'
import { DataTableFilterHeader } from './DataTableFilterHeader'
import { DataTableHeader } from './DataTableHeader'
import { useDataTable } from './hooks/useDataTable'
import { useSetTableSearchParams } from './hooks/useSetTableSearchParam'
import { ServerPagination } from './ServerPagination'
import { DataTableProps, PaginatedResults, Pagination, PaginationSortParam } from './types'

export interface DataTableServerPaginationProps<TColumns, TData>
  extends Omit<DataTableProps<TColumns, TData>, 'data'> {
  data?: PaginatedResults<TData>
  isFetching?: boolean
  setPageParams: ({ page, pageSize, searchTerm }: Pagination) => void
  onSortBy?: (params: PaginationSortParam) => void
}

const DataTableServerPagination = <TColumns, TData>({
  filterPlaceholder,
  fieldDescription,
  columns,
  data,
  setPageParams,
  isFetching = false,
  rowsPerPageOptions = DEFAULT_TABLE_ROWS_PER_PAGE_OPTIONS,
  emptyMessage,
  className,
  onSortBy,
  dataTestId,
  optionalActions,
}: DataTableServerPaginationProps<TColumns, TData> & DataTestId) => {
  const { search: searchTerm, hasValueChanged } = useSetTableSearchParams()

  const [currentPage, setCurrentPage] = useState(1)

  const [currentPageSize, setCurrentPageSize] = useState(DEFAULT_TABLE_ROWS_PER_PAGE)

  const tableData = data?.results

  const { table, sorting, hasSortingChanged, onSortByServer } = useDataTable<TColumns, TData>({
    data: tableData,
    rowsPerPage: currentPageSize,
    columns,
    manualFiltering: true,
    manualSorting: true,
    onSortBy,
  })

  const handleSetPageParamsAndCurrentPage = useCallback(
    ({
      pageSize,
      page,
      searchTerm: searchValue,
    }: {
      pageSize?: number
      page?: number
      searchTerm?: string
    } = {}) => {
      const updatedPage = page ?? currentPage

      setCurrentPage(updatedPage)

      setPageParams({
        searchTerm: searchValue ?? searchTerm,
        pageSize: pageSize ?? currentPageSize,
        page: updatedPage,
      })
    },
    [currentPage, currentPageSize, searchTerm, setPageParams]
  )

  useEffect(() => {
    if (hasValueChanged) {
      if (currentPage !== 1) {
        handleSetPageParamsAndCurrentPage({
          page: currentPage !== 1 ? 1 : currentPage,
        })

        return
      }

      handleSetPageParamsAndCurrentPage()
    }
  }, [currentPage, currentPageSize, handleSetPageParamsAndCurrentPage, hasValueChanged, searchTerm])

  useEffect(() => {
    if (hasSortingChanged && sorting.length > 0) {
      onSortByServer(sorting)
    }
  }, [hasSortingChanged, onSortByServer, sorting])

  const handleSetPageParams = useCallback(
    ({ pageSize, page }: Pagination) => {
      const currentPage = pageSize !== currentPageSize ? 1 : page

      setCurrentPageSize(pageSize)

      handleSetPageParamsAndCurrentPage({
        page: currentPage,
        pageSize,
      })
    },
    [currentPageSize, handleSetPageParamsAndCurrentPage]
  )

  return (
    <>
      <DataTableFilterHeader
        debounce={DEFAULT_SERVER_DEBOUNCE_MS}
        filterPlaceholder={filterPlaceholder}
        fieldDescription={fieldDescription}
      >
        {optionalActions}
      </DataTableFilterHeader>
      <DataTableContainer className={className}>
        <Table data-testid={dataTestId}>
          <DataTableHeader<TData> table={table} />
          <DataTableBody>
            {table.getRowModel().rows?.length ? (
              <DataTableBody.Content<TData> table={table} dataTestId={dataTestId} />
            ) : (
              <DataTableBodyContentEmpty
                colSpanLength={columns.length}
                emptyMessage={emptyMessage}
                dataTestId={dataTestId}
              />
            )}
          </DataTableBody>
        </Table>

        <ServerPagination<TData>
          result={data}
          isFetching={isFetching}
          currentPage={currentPage}
          rowsPerPageOptions={rowsPerPageOptions}
          setPageParams={handleSetPageParams}
        />
      </DataTableContainer>
    </>
  )
}

export default DataTableServerPagination
