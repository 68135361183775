import { Facet } from '@sherweb/core/openapi-generated/index.defs'

import { Skeleton } from '@sherweb/core/components/Skeleton'
import { MarketPlaceFilterReset } from '@sherweb/core/modules/marketplace/ui/Filter'

import { MarketplaceProductsBillingCycleFilter } from './MarketplaceProductsBillingCycleFilter'
import { MarketplaceProductsCommitmentFilter } from './MarketplaceProductsCommitmentFilter'
import { MarketplaceProductsVendorFilter } from './MarketplaceProductsVendorFilter'

type MarketplaceProductsAllFiltersProps = {
  facets?: Facet[]
  isLoading?: boolean
}

export const MarketplaceProductsAllFilters = ({
  facets,
  isLoading,
}: MarketplaceProductsAllFiltersProps) => {
  if (isLoading) {
    return (
      <div className="flex flex-wrap gap-2">
        <Skeleton className="h-[35px] w-[112px]" />
        <Skeleton className="h-[35px] w-[112px]" />
        <Skeleton className="h-[35px] w-[112px]" />
      </div>
    )
  }

  return (
    <div className="flex flex-wrap gap-2">
      <MarketplaceProductsVendorFilter facets={facets} />
      <MarketplaceProductsCommitmentFilter facets={facets} />
      <MarketplaceProductsBillingCycleFilter facets={facets} />
      <MarketPlaceFilterReset />
    </div>
  )
}
