import { DataTestId } from '@sherweb/core/types/dataTestIdType'
import { ComponentChildrenType } from '@sherweb/core/types/reactChildrenType'

import Spinner from '../Spinner'
import { Sheet, SheetContent, SheetHeader, SheetTitle } from './Sheet'
import { SheetPageColumn } from './SheetPageColumn'
import { SheetPageContent } from './SheetPageContent'
import { SheetPageContentLabel } from './SheetPageContentLabel'
import { SheetPageContentValue } from './SheetPageContentValue'
import { SheetPageLayout } from './SheetPageLayout'
import { SheetPageRow } from './SheetPageRow'

interface SheetPageProps extends ComponentChildrenType {
  isLoading?: boolean
  title: React.ReactNode
  onClose: () => void
}

export const SheetPage = ({
  isLoading,
  title,
  children,
  onClose,
  dataTestId,
  ...restProps
}: SheetPageProps & DataTestId) => (
  <Sheet {...restProps} open>
    <SheetContent
      data-testid={dataTestId ?? 'sheetPage'}
      className="flex w-full flex-col gap-0 overflow-auto p-0 sm:w-[550px] sm:p-0"
      closeIconContainerClassName="!top-6 [&>button]:p-0"
      onPointerDownOutside={onClose}
      onEscapeKeyDown={onClose}
      onClose={onClose}
    >
      {isLoading ? (
        <Spinner className="absolute left-[calc(50%-3rem)] top-[calc(50%-3rem)]" />
      ) : (
        <>
          <SheetHeader className="p-6 pr-14">
            <SheetTitle>{title}</SheetTitle>
          </SheetHeader>
          {children}
        </>
      )}
    </SheetContent>
  </Sheet>
)

SheetPage.Row = SheetPageRow
SheetPage.Layout = SheetPageLayout
SheetPage.Column = SheetPageColumn
SheetPage.Content = SheetPageContent
SheetPage.ContentLabel = SheetPageContentLabel
SheetPage.ContentValue = SheetPageContentValue
