import { Facet } from '@sherweb/core/openapi-generated/index.defs'

import { Skeleton } from '@sherweb/core/components/Skeleton'
import { MarketPlaceFilterReset } from '@sherweb/core/modules/marketplace/ui/Filter'

import { ShopBillingCycleFilter } from './components/ShopBillingCycleFilter'
import { ShopCategoryFilter } from './components/ShopCategoryFilter'
import { ShopCommitmentFilter } from './components/ShopCommitmentFilter'
import { ShopVendorFilter } from './components/ShopVendorFilter'

type ShopAllFiltersProps = {
  facets?: Facet[]
  isLoading?: boolean
}

export const ShopAllFilters = ({ facets, isLoading }: ShopAllFiltersProps) => {
  if (isLoading) {
    return (
      <div className="flex flex-wrap gap-2">
        <Skeleton className="h-[35px] w-[112px]" />
        <Skeleton className="h-[35px] w-[112px]" />
        <Skeleton className="h-[35px] w-[112px]" />
        <Skeleton className="h-[35px] w-[112px]" />
      </div>
    )
  }

  return (
    <div className="flex flex-wrap gap-2">
      <ShopCategoryFilter facets={facets} />
      <ShopVendorFilter facets={facets} />
      <ShopBillingCycleFilter facets={facets} />
      <ShopCommitmentFilter facets={facets} />
      <MarketPlaceFilterReset />
    </div>
  )
}
