import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import { SheetPage } from '@sherweb/core/components/Sheet'
import Spinner from '@sherweb/core/components/Spinner'
import { Typography } from '@sherweb/core/components/Typography'
import { OrderDetailContent } from '@sherweb/core/modules/orders/ui/OrderDetailContent'

import { protectPage } from '@rsp/app/ProtectedPage'
import Routes from '@rsp/app/Routes'
import { useGetOrderQuery } from '@rsp/modules/orders'

const OrderDetailPage = () => {
  const { t } = useTranslation()

  const { orderId } = useParams()

  const orderQuery = useGetOrderQuery(orderId)

  const navigate = useNavigate()

  const isLoading = orderQuery.isLoading || !orderQuery?.data

  const handleClose = () => {
    navigate(Routes.Orders)
  }

  return (
    <SheetPage
      dataTestId="sheetOrderDetailsPage"
      title={
        isLoading ? null : (
          <Typography variant="heading6" weight="bold" className="text-wrap">
            {t('core:orders.detail.title', { orderId: orderQuery?.data?.humanReadableId })}
          </Typography>
        )
      }
      onClose={handleClose}
    >
      {isLoading ? (
        <Spinner
          dataTestId="layoverSpinnner"
          className="absolute left-[calc(50%-3rem)] top-[calc(50%-3rem)] z-10"
        />
      ) : null}
      <OrderDetailContent order={orderQuery?.data} />
    </SheetPage>
  )
}

export default protectPage(OrderDetailPage, Permission.AdminOrderDetails)
