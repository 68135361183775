import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import Button, { Variant } from '@sherweb/core/components/Button'
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@sherweb/core/components/Drawer'
import LoaderButton from '@sherweb/core/components/LoaderButton'
import { usePrompt } from '@sherweb/core/components/Prompt'
import { Skeleton, SkeletonTable } from '@sherweb/core/components/Skeleton'
import { isRequestLoading } from '@sherweb/core/modules/reactQuery'

import { protectPage } from '@rsp/app/ProtectedPage'
import Routes from '@rsp/app/Routes'
import { useGetAvailableProductsQuery, useGetCatalogQuery } from '@rsp/modules/martkeplace'
import { useNavigate } from '@rsp/modules/navigation'

import { useGetCatalogId } from '../hooks/useGetCatalogId'
import { MarketplaceProductsAllFilters } from './components/MarketplaceProductsAllFilters'
import { MarketplaceProductsSearch } from './components/MarketplaceProductsSearch'
import { useGetFilteredProducts } from './hooks/useGetFilteredProducts'
import { useSelectedOffersChanged } from './hooks/useSelectedOffersChanged'
import { useUpdateCatalogProducts } from './hooks/useUpdateCatalogProducts'
import { MarketplaceCatalogsAddProductsConfirmationModal } from './MarketplaceCatalogsAddProductsConfirmationModal'
import { MarketplaceCatalogsAddProductsTable } from './MarketplaceCatalogsAddProductsTable'

const MarketplaceCatalogsAddProductsPage = () => {
  const { t } = useTranslation()

  const [isConfirmationOpened, setIsConfirmationOpend] = useState(false)

  const { catalogId } = useGetCatalogId()

  const availableProducts = useGetAvailableProductsQuery(catalogId)

  const isLoading = isRequestLoading(availableProducts)

  const catalogQuery = useGetCatalogQuery(catalogId)

  const { setCatalogProductsMutation, onUpdateCatalogProducts } = useUpdateCatalogProducts()

  const { navigate } = useNavigate()

  const { selectedOffers, setSelectedOffers, filteredProducts, totalOffersCount } =
    useGetFilteredProducts(availableProducts?.data)

  const isDirty = useSelectedOffersChanged({
    alreadyAddedOffers: availableProducts?.data?.selectedOffers ?? [],
    selectedOffers,
  })

  const redirectToCatalogProductsPage = () => {
    navigate(Routes.MarketplaceCatalogProducts, {
      catalogId,
    })
  }

  const handleUpdateCatalog = useCallback(() => {
    setIsConfirmationOpend(true)
  }, [])

  const handleSubmitConfirmation = () => {
    onUpdateCatalogProducts(availableProducts?.data?.allOffers ?? [], selectedOffers)
  }

  const handleCloseConfirmation = () => {
    setIsConfirmationOpend(false)
  }

  usePrompt({
    isDirty,
    noConfirmationWithChangeOfQueryParamter: true,
  })

  return (
    <>
      <Drawer open>
        <DrawerContent className="ml-0 mr-[6px] flex h-5/6 flex-col outline-none lg:ml-[260px]">
          <>
            <DrawerHeader
              className="p-4 pb-8 text-start md:pl-12"
              onClose={() => {
                redirectToCatalogProductsPage()
              }}
            >
              <DrawerTitle className="dark:text-white">
                {isRequestLoading(catalogQuery) ? (
                  <Skeleton className="h-[18px] w-[264px]" />
                ) : (
                  t('rsp:pages.marketplace.addProducts.title', { name: catalogQuery?.data?.name })
                )}
              </DrawerTitle>
              <DrawerDescription className="dark:text-white">
                {t('rsp:pages.marketplace.addProducts.description')}
              </DrawerDescription>
              <div className="mt-6 flex flex-col gap-4">
                <MarketplaceProductsSearch />
                <MarketplaceProductsAllFilters
                  facets={availableProducts?.data?.facets}
                  isLoading={isLoading}
                />
              </div>
            </DrawerHeader>
            {isLoading ? (
              <div className="p-4 md:pl-12">
                <SkeletonTable count={4} />
              </div>
            ) : (
              <MarketplaceCatalogsAddProductsTable
                totalOffersCount={totalOffersCount}
                filteredProducts={filteredProducts}
                offers={selectedOffers}
                onSelectOffers={setSelectedOffers}
              />
            )}
            <DrawerFooter className="sticky bottom-2 p-4 pt-6 md:pr-12">
              <div className="flex justify-end gap-4">
                <Button
                  variant={Variant.Outline}
                  data-testid="btnCancelManageCatalog"
                  onClick={() => {
                    redirectToCatalogProductsPage()
                  }}
                >
                  {t('core:actions.cancel')}
                </Button>
                <LoaderButton
                  disabled={isLoading || !isDirty || setCatalogProductsMutation.isLoading}
                  dataTestId="btnUpdateCatalog"
                  isLoading={setCatalogProductsMutation.isLoading}
                  onClick={handleUpdateCatalog}
                >
                  {t('rsp:pages.marketplace.addProducts.updateCatalog')}
                </LoaderButton>
              </div>
            </DrawerFooter>
          </>
        </DrawerContent>
      </Drawer>
      <MarketplaceCatalogsAddProductsConfirmationModal
        open={isConfirmationOpened}
        onClose={handleCloseConfirmation}
        changedProductsCount={selectedOffers.length}
        onSubmit={handleSubmitConfirmation}
      />
    </>
  )
}

export default protectPage(MarketplaceCatalogsAddProductsPage, Permission.CatalogProductsEdit)
