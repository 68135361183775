import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
  CatalogOrganizationQueryResult,
  Permission,
} from '@sherweb/core/openapi-generated/index.defs'

import Button, { Variant } from '@sherweb/core/components/Button'
import { InfiniteScroll } from '@sherweb/core/components/InfiniteScroll'
import LoaderButton from '@sherweb/core/components/LoaderButton'
import { usePrompt } from '@sherweb/core/components/Prompt'
import { SheetPage } from '@sherweb/core/components/Sheet'
import Spinner from '@sherweb/core/components/Spinner'
import { Typography } from '@sherweb/core/components/Typography'
import { getAllPagesResults, isRequestLoading } from '@sherweb/core/modules/reactQuery'

import { protectPage } from '@rsp/app/ProtectedPage'
import {
  useGetAssignedCatalogOrganizationIdsQuery,
  useGetCatalogQuery,
  useGetInfiniteScrollQueryCatalogOrganizations,
  useUpdateCatalogOrganizationsMutation,
} from '@rsp/modules/martkeplace'

import { useGetCatalogId } from '../hooks/useGetCatalogId'
import { useAssignedOrganizations } from './hooks/useAssignedOrganizations'
import { MarketplaceCatalogsAssignedOrganizationsConfirmationDialog } from './MarketplaceCatalogsAssignedOrganizationsConfirmationDialog'
import { MarketplaceCatalogsAssignedOrganizationsContent } from './MarketplaceCatalogsAssignedOrganizationsContent'
import { MarketplaceCatalogsAssignedOrganizationsHeader } from './MarketplaceCatalogsAssignedOrganizationsHeader'

const MarketplaceCatalogsAssignedOrganizationsPage = () => {
  const { t } = useTranslation()

  const [search, setSearch] = useState('')

  const { catalogId } = useGetCatalogId()

  const catalogQuery = useGetCatalogQuery(catalogId)

  const [confirmationDialogOpened, setConfirmationDialogOpened] = useState(false)

  const catalogOrganizationsQuery = useGetInfiniteScrollQueryCatalogOrganizations(catalogId, {
    query: search,
  })

  const initiallyAssignedOrganizationIds = useGetAssignedCatalogOrganizationIdsQuery(catalogId)

  const setCatalogOrganizationsMutation = useUpdateCatalogOrganizationsMutation(catalogId)

  const catalogOrganizations =
    getAllPagesResults<CatalogOrganizationQueryResult>(catalogOrganizationsQuery)

  const {
    selectedAssignedOrganizationIds,
    setSelectedAssignedOrganizationIds,
    selectedRemovedOrganizationIds,
    setSelectedRemovedOrganizationIds,
    selectedOrganizationsCount,
    reset,
    redirectToProductsPage,
  } = useAssignedOrganizations({
    initiallyAssignedOrganizationIds: initiallyAssignedOrganizationIds?.data,
  })

  const isDirty =
    selectedAssignedOrganizationIds.length !== 0 || selectedRemovedOrganizationIds.length !== 0

  useEffect(() => {
    if (setCatalogOrganizationsMutation.isSuccess) {
      reset()
      redirectToProductsPage()
    }
  }, [reset, redirectToProductsPage, setCatalogOrganizationsMutation.isSuccess])

  const handleFetchNextPage = async () => {
    await catalogOrganizationsQuery.fetchNextPage()
  }

  const handleUpdateCatalog = () => {
    setConfirmationDialogOpened(true)
  }

  usePrompt({
    isDirty,
  })

  const cancelConfirmation = () => {
    setConfirmationDialogOpened(false)
  }

  const onSubmit = () => {
    setCatalogOrganizationsMutation.mutate({
      organizationsToAdd: selectedAssignedOrganizationIds,
      organizationsToRemove: selectedRemovedOrganizationIds,
    })
  }

  return (
    <>
      <SheetPage
        title={
          <Typography variant="heading6" weight="bold" className="text-wrap" as="div">
            {t('rsp:pages.marketplace.manageOrganizations.title')}
          </Typography>
        }
        onClose={redirectToProductsPage}
      >
        <MarketplaceCatalogsAssignedOrganizationsHeader search={search} setSearch={setSearch} />
        {isRequestLoading(catalogOrganizationsQuery) ? (
          <Spinner
            dataTestId="layoverSpinnner"
            className="absolute left-[calc(50%-3rem)] top-[calc(50%-3rem)] z-10"
          />
        ) : (
          <MarketplaceCatalogsAssignedOrganizationsContent
            catalogOrganizations={catalogOrganizations}
            selectedRemovedOrganizationIds={selectedRemovedOrganizationIds}
            selectedAssignedOrganizationIds={selectedAssignedOrganizationIds}
            onAssignOrganizations={setSelectedAssignedOrganizationIds}
            onUnAssignOrganizations={setSelectedRemovedOrganizationIds}
            infiniteScrollComponent={
              catalogOrganizationsQuery?.hasNextPage ? (
                <InfiniteScroll
                  isLoading={catalogOrganizationsQuery.isFetchingNextPage}
                  hasMore={catalogOrganizationsQuery?.hasNextPage}
                  next={handleFetchNextPage}
                />
              ) : null
            }
            title={
              <Typography variant="label" as="div">
                <Trans
                  i18nKey="rsp:pages.marketplace.manageOrganizations.selectedOrganizationsLabel"
                  values={{ selectedOrganizationsCount }}
                  count={selectedOrganizationsCount}
                />
              </Typography>
            }
          />
        )}

        <div className="sticky bottom-2 mt-auto flex flex-col gap-2 p-6">
          <div className="flex justify-end gap-4">
            <Button
              variant={Variant.Outline}
              data-testid="btnCancelManageCatalog"
              onClick={redirectToProductsPage}
            >
              {t('core:actions.cancel')}
            </Button>
            <LoaderButton
              disabled={
                !isDirty ||
                isRequestLoading(catalogOrganizationsQuery) ||
                setCatalogOrganizationsMutation.isLoading
              }
              isLoading={setCatalogOrganizationsMutation.isLoading}
              data-testid="btnUpdateCatalog"
              onClick={handleUpdateCatalog}
            >
              {t('rsp:pages.marketplace.manageOrganizations.updateCatalog')}
            </LoaderButton>
          </div>
        </div>
      </SheetPage>
      <MarketplaceCatalogsAssignedOrganizationsConfirmationDialog
        cancelConfirmation={cancelConfirmation}
        onSubmit={onSubmit}
        confirmationDialogOpened={confirmationDialogOpened}
        catalogName={catalogQuery?.data?.name}
        organizationsNumber={selectedOrganizationsCount}
      />
    </>
  )
}

export default protectPage(
  MarketplaceCatalogsAssignedOrganizationsPage,
  Permission.CatalogOrganizationsList
)
