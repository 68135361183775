import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import isEqual from 'react-fast-compare'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from '@sherweb/core/components/Button'
import { Form } from '@sherweb/core/components/Form'
import LoaderButton from '@sherweb/core/components/LoaderButton'
import { usePrompt } from '@sherweb/core/components/Prompt'
import { Separator } from '@sherweb/core/components/Separator'
import { SheetPage } from '@sherweb/core/components/Sheet'
import { usePrevious } from '@sherweb/core/hooks'

import { useSetOrganizationSettings } from '@rsp/modules/organizations'
import { getOrganizationsSettingsDefaultValues } from '@rsp/modules/organizations/core/organizations.helpers'
import { DetailedOrganizationType } from '@rsp/modules/organizations/core/organizations.model'

import { OrganizationDetailPortalFeatures } from './OrganizationDetailPortalFeatures'
import { OrganizationDetailSubscriptionSettings } from './OrganizationDetailSubscriptionSettings'
import { OrganizationDetailUserSettings } from './OrganizationDetailUserSettings'
import useOrganizationDetailSchema, {
  OrganizationDetailFormType,
} from './validationSchema/useOrganizationDetailSchema'

type OrganizationDetailContentProps = {
  organization?: DetailedOrganizationType
  onClose: () => void
}

const DEFAULT_FORM_VALUES = getOrganizationsSettingsDefaultValues()

export const OrganizationDetailContent = ({
  organization,
  onClose,
}: OrganizationDetailContentProps) => {
  const { t } = useTranslation()

  const schema = useOrganizationDetailSchema()

  const setOrganizationSettings = useSetOrganizationSettings(organization?.organizationId)

  const previousOrganization = usePrevious(organization)

  const form: UseFormReturn<OrganizationDetailFormType> = useForm({
    resolver: zodResolver(schema),
  })

  const isAnyFieldDirty = Object.keys(form.formState.dirtyFields).length > 0

  usePrompt({
    isDirty: isAnyFieldDirty,
  })

  useEffect(() => {
    if (setOrganizationSettings.isSuccess) {
      onClose()
      form.reset(DEFAULT_FORM_VALUES)
    }
  }, [form, onClose, setOrganizationSettings.isSuccess])

  useEffect(() => {
    if (!isEqual(previousOrganization, organization)) {
      form.reset({ ...organization })
    }
  }, [form, organization, previousOrganization])

  const onSubmit = (values: OrganizationDetailFormType) => {
    setOrganizationSettings.mutate({
      command: {
        ...values,
      },
    })
  }

  if (!organization) {
    return null
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex h-full flex-col">
        <Separator className="bg-slate-300 dark:bg-slate-700" />
        <SheetPage.Layout>
          <SheetPage.Row>
            <SheetPage.Column>
              <SheetPage.Content>
                <SheetPage.ContentLabel>
                  {t('rsp:pages.organizations.list.users')}
                </SheetPage.ContentLabel>
                <SheetPage.ContentValue>{organization?.userCount}</SheetPage.ContentValue>
              </SheetPage.Content>
            </SheetPage.Column>
          </SheetPage.Row>
        </SheetPage.Layout>
        <Separator className="bg-slate-300 dark:bg-slate-700" />
        <SheetPage.Layout className="flex h-full flex-col">
          <OrganizationDetailPortalFeatures />
          <OrganizationDetailSubscriptionSettings />
          <OrganizationDetailUserSettings />
          <div className="flex h-full items-end justify-end space-x-2">
            <Button variant="secondary" type="button" size="sm" onClick={() => onClose()}>
              {t('rsp:pages.organizations.detail.actions.cancel')}
            </Button>
            <LoaderButton
              type="submit"
              className=""
              isLoading={setOrganizationSettings.isLoading}
              loadingChildren={t('rsp:pages.organizations.detail.actions.saving')}
            >
              {t('rsp:pages.organizations.detail.actions.save')}
            </LoaderButton>
          </div>
        </SheetPage.Layout>
      </form>
    </Form>
  )
}
